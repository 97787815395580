<div class="page-scan">
  <!-- SCROLLABLE -->
  <div class="page-scan-scroll">
    <!-- titre -->
    <h1 class="page-titre page-marge"><mat-icon>qr_code_scanner</mat-icon>Scan 1</h1>

    <!--***********************************************************************-->
    <!--******************  Clavier numérique (overlay modal) *****************-->
    <!--***********************************************************************-->
    <div class="kbd-bg" [class.kbd-hide]="!numericKeyboard.displayNumericKeyboard">
      <div class="kbd-container">
        <div class="kbd-titre">{{ numericKeyboard.title }}</div>
        <div>
          <input class="kbd-input" id="code" [(ngModel)]="numericKeyboard.valueNumericKeyboard" />
        </div>
        <div
          *ngIf="Selection_NbTraitementSelection > 0 && Selection_QtyRemainingToScan() - numericKeyboard.valueNumericKeyboardAsNumber < 0"
          class="kbd-alert-txt"
          id="numericKeyboardAlertMsg"
        >
          Attention, le nombre de produits scannés est supérieur au nombre attendu.
        </div>
        <div>
          <button mat-stroked-button type="button" class="kbd-btn-long" (click)="addNbrScanneAsNumber(-1)">-1</button>
          <button mat-stroked-button type="button" class="kbd-btn-long" (click)="addNbrScanneAsNumber(1)">+1</button>
        </div>
        <div>
          <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addNbrScanne('1')">1</button>
          <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addNbrScanne('2')">2</button>
          <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addNbrScanne('3')">3</button>
        </div>
        <div>
          <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addNbrScanne('4')">4</button>
          <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addNbrScanne('5')">5</button>
          <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addNbrScanne('6')">6</button>
        </div>
        <div>
          <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addNbrScanne('7')">7</button>
          <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addNbrScanne('8')">8</button>
          <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addNbrScanne('9')">9</button>
        </div>
        <div>
          <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="removeNbrScanne(false)">
            <mat-icon>keyboard_backspace</mat-icon>
          </button>
          <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="addNbrScanne('0')">0</button>
          <button mat-stroked-button color="primary" type="button" class="kbd-btn" (click)="removeNbrScanne(true)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div>
          <button mat-stroked-button color="primary" class="kbd-btn-footer" type="button" (click)="CloseNumericKeyboard(false)">
            <mat-icon>cancel</mat-icon> Annuler
          </button>
          <button mat-flat-button color="primary" class="kbd-btn-footer" type="button" (click)="CloseNumericKeyboard(true)">
            <mat-icon>check</mat-icon> Valider
          </button>
        </div>
      </div>
    </div>

    <!--***********************************************************************-->
    <!--****  Section 'Mode réception' + voir produits restants ***************-->
    <!--***********************************************************************-->
    <div class="info-container page-marge">
      <div class="info-txt-container">
        <span class="info-txt-label">Modes de réception : Réception</span>
        <span class="info-txt-value">
          <span *ngIf="!(qteScannedHeader === null || qteTotalHeader === null)">({{ qteScannedHeader }} items sur {{ qteTotalHeader }})</span>
        </span>
      </div>
      <div>
        <button
          mat-stroked-button
          color="primary"
          class="info-btn"
          (click)="goToLink('/reception-detail;ItemReceptionHeader.IsCompleted=false;LigneNonTerminee=true')"
        >
          Voir produits restants
        </button>
        <button mat-stroked-button color="primary" class="info-btn" (click)="goToLink('/reception-detail;ItemReceptionHeader.IsCompleted=false')">
          Voir état réception
        </button>
      </div>
    </div>

    <!--***********************************************************************-->
    <!--****  Section 'SCAN SKU', image du produit en cours...   **************-->
    <!--***********************************************************************-->
    <section class="scan-sku-container page-marge">
      <div *ngIf="CurrentProductInfo.imageUrl !== undefined">
        <a href="{{ CurrentProductInfo.imageUrl }}" target="_blank">
          <img src="{{ CurrentProductInfo.imageUrl }}" alt="Photo of a Shiba Inu" class="image-produit-small" />
        </a>
      </div>
      <div>
        <form [formGroup]="formT" (ngSubmit)="onSubmitFormSearchItem()">
          <label for="upc" class="input-label">Scan/SKU&nbsp;</label>
          <input class="input-stroked" type="text" id="upc" formControlName="data" />
          <button mat-flat-button color="primary" [disabled]="formT.invalid"><mat-icon>search</mat-icon>Rechercher</button>
        </form>

        <div *ngIf="!UneStationEstSelectionnee">
          <p style="color: red" [innerHTML]="globalErrorMessage"></p>
        </div>

        <div class="scan-sku-txt">
          <span class="scan-sku-label">Description Produit : </span>
          <span *ngIf="CurrentProductInfo.SkuCleaned !== undefined">({{ CurrentProductInfo.SkuCleaned }}) {{ CurrentProductInfo.Title }}</span>
        </div>
      </div>

      <div>
        <div class="scan-sku-qte">
          <div class="scan-sku-label">Qté totale réception :</div>
          <div class="scan-sku-value">{{ QteTotalToReceive }}</div>
          <button
            mat-icon-button
            matTooltip="Visualiser toutes les LIGNES DE RÉCEPTIONS pour ce produit"
            color="Basic"
            type="button"
            [disabled]="CurrentProductInfo.SkuCleaned === undefined"
            (click)="goToLink('/reception-detail;ItemReceptionHeader.IsCompleted=false;ProductNumber=' + CurrentProductInfo.SkuCleaned)"
          >
            <mat-icon>search</mat-icon>
          </button>
        </div>
        <div class="scan-sku-qte">
          <div class="scan-sku-label">Qté totale scannée :</div>
          <div class="scan-sku-value">{{ QteAlreadyScanned }}</div>
          <button
            mat-icon-button
            matTooltip="{{ TooltipQtyToReceive }}"
            color="Basic"
            type="button"
            [disabled]="CurrentProductInfo.SkuCleaned === undefined"
            (click)="goToLink('/reception-trace;ProductNumber=' + CurrentProductInfo.SkuCleaned)"
          >
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
    </section>
    <mat-error *ngIf="displayNoDataAlertMessage"> Aucune ligne de réception à faire avec le code de produit saisie. Déclarer plutôt un problème. </mat-error>
    <mat-error *ngIf="ProductDontExistError">
      Le code produit ou le code UPC que vous venez de saisir '{{ this.CurrentSelectedSku }}' semble ne pas exister!
    </mat-error>

    <!--***********************************************************************-->
    <!--*********** Section principale : Liste des Destination possible *******-->
    <!--***********************************************************************-->
    <div class="page-marge">
      <table mat-table [dataSource]="listPossibleDestination" class="table-liste">
        <!-- Priorité -->
        <ng-container matColumnDef="LineNumber">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let order">{{ order.LineNumber }}</td>
        </ng-container>

        <!-- Qte scannée -->
        <ng-container matColumnDef="HeaderId">
          <th mat-header-cell *matHeaderCellDef>HeaderId</th>
          <td mat-cell *matCellDef="let order">{{ order.ItemReceptionHeaderId }}</td>
        </ng-container>

        <!-- Destination -->
        <ng-container matColumnDef="Destination">
          <th mat-header-cell *matHeaderCellDef>Destination Pieuvre</th>
          <td mat-cell *matCellDef="let order">
            <span>{{ order.FeededDestination.destination_scan1 }}</span>

            <span *ngIf="order.CA_IsToPriorized" [class.text-danger]="order.CA_IsToPriorized" class="bi bi-clock"> PRIORITAIRE</span>
          </td>
        </ng-container>

        <!-- Destination -->
        <ng-container matColumnDef="subDestination">
          <th mat-header-cell *matHeaderCellDef>Détail pieuvre</th>
          <td mat-cell *matCellDef="let order">
            <span>{{ order.FeededDestination.codeDestination }} {{ order.subDestination }}</span>
          </td>
        </ng-container>

        <!-- HH Code Conso -->
        <ng-container matColumnDef="HHCodeConso">
          <th mat-header-cell *matHeaderCellDef>CodeConso</th>
          <td mat-cell *matCellDef="let order">{{ order.HH_ConsumerNumber }}</td>
        </ng-container>

        <!-- Qte totale -->
        <ng-container matColumnDef="Qtetotale">
          <th mat-header-cell *matHeaderCellDef>Qte totale</th>
          <td mat-cell *matCellDef="let order">{{ order.qtyToReceive }}</td>
        </ng-container>

        <!-- Qte scannée -->
        <ng-container matColumnDef="Qtescann">
          <th mat-header-cell *matHeaderCellDef>Qte déjà scannée</th>
          <td mat-cell *matCellDef="let order">{{ order.qtyAlreadyProcessed }}</td>
        </ng-container>

        <!-- Sélection -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>Sélection</th>
          <td mat-cell *matCellDef="let order">
            <mat-checkbox (click)="$event.preventDefault()" [checked]="order.IsSelectedForProcess"> </mat-checkbox>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="['LineNumber', 'HeaderId', 'Destination', 'subDestination', 'HHCodeConso', 'Qtetotale', 'Qtescann', 'select']"
        ></tr>

        <tr
          mat-row
          [class.bgSelected]="row.IsSelectedForProcess"
          *matRowDef="let row; columns: ['LineNumber', 'HeaderId', 'Destination', 'subDestination', 'HHCodeConso', 'Qtetotale', 'Qtescann', 'select']"
          (click)="OnClickLigneReception(row, $event)"
        ></tr>
        <!---->
      </table>
    </div>
  </div>

  <!--***********************************************************************-->
  <!--*************  Section du bas de l'écran  *****************************-->
  <!--******* [Qté totale commande, Bouton Probleme, confirmer, etc] ********-->
  <!--***********************************************************************-->
  <div class="page-scan-fixed">
    <!-- Destination + Quantités-->
    <section class="qte-scan-container">
      <div style="width: 25%">
        <div class="qte-scan-destination">
          Destination :
          <span class="green">{{ Selection_ListDestination }}</span>
        </div>
        <div class="qte-scan-destination">
          Site Order ID : <span>{{ Selection_ListSiteOrderID }}</span>
        </div>
      </div>

      <div style="width: 20%">
        <div class="qte-scan-label">Qté totale commande</div>
        <div class="qte-scan-txt">
          <span>{{ Selection_QtyEnCommandeTotale }}</span>
        </div>
      </div>
      <div style="width: 20%">
        <div class="qte-scan-label">Qté déjà scannée</div>
        <div class="qte-scan-txt">
          <span>{{ Selection_QtyAlreadyScanned }}</span>
        </div>
      </div>

      <div style="width: 35%; text-align: right">
        <div class="qte-scan-label">Quantité de votre SCAN</div>

        <input class="input-stroked qte-scan-input" type="number" [readonly]="true" [(ngModel)]="scanQte" (click)="openNumericKeyboard()" />
        <button mat-flat-button (click)="openNumericKeyboard()" class="qte-scan-btn">
          <mat-icon>calculate</mat-icon>
        </button>
        <button mat-flat-button (click)="scanQte_AddOne()" class="qte-scan-btn">
          <mat-icon>add_box</mat-icon>
        </button>
        <button mat-flat-button (click)="scanQte_RemoveOne()" class="qte-scan-btn" Gros="OUI">
          <mat-icon>indeterminate_check_box</mat-icon>
        </button>
      </div>
    </section>

    <!-- section confirmation (les 4 boutons du bas) -->
    <section class="confirmer-container">
      <div class="Problem-btn" [class.disabled]="CurrentProductInfo.SkuCleaned === undefined">
        <button mat-button (click)="on_main_Problem()"><mat-icon>redo</mat-icon> Problème</button>
      </div>

      <div
        class="confirmer-btn"
        [class.norights2]="!scan2rights"
        [class.disabled]="CurrentProductInfo.SkuCleaned === undefined || Selection_NbTraitementSelection === 0"
      >
        <button mat-button (click)="on_main_confirmation()" [disabled]="lockConfirmButton"><mat-icon>check</mat-icon> Confirmer</button>
      </div>

      <div class="annuler-btn">
        <button mat-button (click)="on_main_cancel()"><mat-icon>cancel</mat-icon> Annuler</button>
      </div>
    </section>
  </div>
</div>
