import { Component, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, FilterDescriptor } from '@progress/kendo-data-query';
import { BehaviorSubject, take, Subscription } from 'rxjs';
import { UtilService, FieldType } from 'src/app/service/util.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceAPService } from 'src/app/service/invoiceAp.service';
import { PopConfirmComponent } from 'src/app/component/core/pop-confirm/pop-confirm.component';
import { PopUpDialogService } from 'src/app/service/popUpDialog.service';

import { InvoiceAPHeaderSync } from 'src/app/models/supplier/InvoiceApSync';
import { InvoicesAPProcessingOptions } from 'src/app/models/supplier/InvoicesAPProcessingOptions';
import { dateFieldName } from '@progress/kendo-angular-intl';

@Component({
  selector: 'app-invoices-ap-list',
  templateUrl: './invoices-ap-list.component.html',
  styleUrls: ['./invoices-ap-list.component.scss'],
})
export class InvoicesAPListComponent implements OnInit {
  @ViewChild(GridComponent) grid!: GridComponent;
  public fieldTypes: BehaviorSubject<FieldType[]> = new BehaviorSubject([]);

  public gridView!: GridDataResult;
  pageable = {
    pageSizes: [10, 25, 50, 100, 500, 1000],
  };
  public isLoading = false;
  public totalItemCount = 0;

  public initSub: Subscription | null = null;
  public refreshSub: Subscription | null = null;

  constructor(
    private cd: ChangeDetectorRef,
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private utilService: UtilService,
    private InvoiceApService: InvoiceAPService,
    private popUpDialogService: PopUpDialogService
  ) {
    this.processingOptions.dateEnd = new Date();
    this.processingOptions.dateEnd.setHours(0, 0, 0, 0);
    this.processingOptions.dateStart = new Date(new Date().setDate(this.processingOptions.dateEnd.getDate() - 30));
    this.processingOptions.dateStart.setHours(0, 0, 0, 0);
    this.processingOptions.AllowERPInvoiceAPCreation = true;
    this.processingOptions.AllowSupplierDataExtraction = true;
  }

  public state: State = {
    take: 25,
    skip: 0,
    sort: [{ field: 'Ctrl_ERP_InvoiceAP_CreatedDate', dir: 'desc' }],
    filter: {
      filters: [],
      logic: 'and',
    },
  };

  TitreGlobalPage: string = 'Facture Fournisseur';
  IconGlobalPage: string = 'percent';

  selectedItem: any; //représente l'entité actuellement sélectionnée
  selectedRow: any = null; //représente, dans le matTAble la ligne en cours de sélection...
  needRefresh: boolean = false;

  processingOptions = {} as InvoicesAPProcessingOptions;

  actionZoneLaunchExtractExpanded = false;

  ngOnInit(): void {
    //Setup la pagination
    this.state.take = this.utilService.getRowPerPage();
    this.processingOptions.SupplierCodeList = ['HOME'];

    // subscribe to data
    this.initSub = this.InvoiceApService.InvoicesAP.subscribe((response) => {
      // set grid data
      this.totalItemCount = response['@odata.count'];
      this.gridView = {
        data: response.value,
        total: response['@odata.count'],
      };
    });

    this.CheckUrlForFilters();
  }

  ngAfterViewInit(): void {
    // Puisqu'on change des données à la fin d'un change detection cycle, il faut manuellement dire à Angular qu'il ya eu des changements.
    // Sinon, sa fait des exceptions: NG0100.
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.refreshSub) this.refreshSub.unsubscribe();
    if (this.initSub) this.initSub.unsubscribe();
  }

  refresh() {
    this.isLoading = true;
    this.refreshSub = this.InvoiceApService.odata_Rates(this.state).subscribe((res) => {
      this.isLoading = false;
    });
  }

  dataStateChange(state: State) {
    this.utilService.setRowPerPage(state.take ?? 25);
    this.state = state;
    this.refresh();
    this.UpdateUrlWithFilter();
  }

  clearFilters(): void {
    this.state.filter = {
      logic: 'and',
      filters: [],
    };
    this.refresh();
  }

  public formatDate(_date: Date): string {
    return this.utilService.formatDateToString(_date);
  }

  //ici, c'est qu'on a cliqué sur le panneau de protection de 'changement détecté'.  ca permet de faire un DISCARD
  async LaunchInvoiceAPExtraction() {
    let Codefournisseur = 'HOMEHARDWARE_CONNECT'; // this.processingOptions.SupplierCodeList[0]; //TODO!
    let DateDebut = this.processingOptions.dateStart.toISOString().substring(0, 10);
    let DateFin = this.processingOptions.dateEnd.toISOString().substring(0, 10);
    let CreationAcomba = this.processingOptions.AllowERPInvoiceAPCreation ? 'OUI' : 'NON';
    let ExtractSupplier = this.processingOptions.AllowSupplierDataExtraction ? 'OUI' : 'NON';

    var DataToPass = {
      data: {
        titre: 'Lancer une extraction/Crawler',
        texte: `Êtes-vous certain de vouloir lancer une extraction des factures fournisseur?<br/><br/>
                Vos paramètre:<br/>
                - Fournisseur : ${Codefournisseur}<br/>
                - Date :  Du ${DateDebut} au ${DateFin}<br/>
                - Extraire des systemes Fournisseur [ex:Connect] : ${ExtractSupplier}<br/>
                - Créer dans Acomba? : ${CreationAcomba}<br/>
                `,
        affirmativeActionName: "Lancer l'extraction",
        affirmativeActionColor: 'primary',

        dismissiveActionName: 'Annuler',
        dismissiveActionColor: 'warn',
      },
    };
    var dialogRef = await this.popUpDialogService.FullDialog(DataToPass.data);

    if (dialogRef === true) {
      if (Codefournisseur == 'HOMEHARDWARE_CONNECT') {
        this.InvoiceApService.LaunchAccountPayableInvoiceProcessing(this.processingOptions).subscribe((jsonData) => {
          console.log(jsonData);
          this.popUpDialogService.SimpleErrorAlert('La facture a été rafraichit' + jsonData, 'OK', 'model_training');
        });
      }
    }
  }

  async ReprocessOneElement(dataItem: InvoiceAPHeaderSync) {
    let MyConfigReload = new InvoicesAPProcessingOptions();
    MyConfigReload.ReprocessSpecificInvoicesById = [dataItem.SupplierInvoicesHeaderSyncId];
    MyConfigReload.AllowERPInvoiceAPCreation = true;
    MyConfigReload.AllowSupplierDataExtraction = false;
    let NoFour = dataItem.ERP_SupplierCode ?? 'VIDE';
    let NoFacture = dataItem.ERP_InvoiceNumber ?? 'VIDE';
    let DateFacture = dataItem.ERP_InvoiceDate; //.toISOString().substring(0, 10);

    var DataToPass = {
      data: {
        titre: 'Relancer une création ERP',
        texte: `Êtes-vous certain de vouloir tenter de recréer cette transaction dans votre ERP??<br/><br/>
                Vos paramètre:<br/>
                - Fournisseur : ${NoFour}<br/>
                - No Facture : ${NoFacture}<br/>
                - Date Facture : ${DateFacture}}<br/>
                `,
        affirmativeActionName: 'Relancer la création',
        affirmativeActionColor: 'primary',

        dismissiveActionName: 'Annuler',
        dismissiveActionColor: 'warn',
      },
    };
    var dialogRef = await this.popUpDialogService.FullDialog(DataToPass.data);

    if (dialogRef === true) {
      this.InvoiceApService.LaunchAccountPayableInvoiceProcessing(MyConfigReload).subscribe((jsonData) => {
        console.log(jsonData);
        this.popUpDialogService.SimpleErrorAlert('La facture recréation a été complétée.<br/><br/>Retour:' + jsonData, 'OK', 'model_training');
      });
    }
  }

  /** Permet de loader les filtres Kendo à partir des query Params */
  private CheckUrlForFilters(): void {
    this.route.queryParams.subscribe((params) => {
      const filters = this.utilService.parseParamForKendoAutoFilter(params);
      if (filters.length > 0) {
        this.state.filter.filters = filters;
      }
      this.refresh();
    });
  }

  /** Permet de modifier le URI en cours pour y ajouter les filtres qu'on a sur notre gridKendo */
  private UpdateUrlWithFilter(): void {
    const queryParams = this.utilService.buildQueryParamsFromFilters(this.state.filter);

    //on va remmplacer l'adresse écrite dans le navigateur,   SANS NAVIGUER, pour refléter les filtres actuels

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      replaceUrl: true,
    });
  }
}
