// basic angular modules
import { ErrorHandler, NgModule } from '@angular/core';
// Pieuvre modules
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { logByPinFormComponent } from './component/auth/logByPin/logByPin.component';
import { LoginUserPwdComponent } from './component/auth/logUserPwd/LoginUserPwd.component';
import { WorkstationListComponent } from './component/auth/workstation-list/workstation-list.component';
import { UsagerListComponent } from './component/auth/usager-list/usager-list.component';

import { Scan1Component } from './component/scan1/scan1.component';
import { Scan2EmballeurComponent } from './component/scan2-emballeur/scan2-emballeur.component';
import { OnlineOrderListComponent } from './component/OnlineOrders/online-order-list/online-order-list.component';

import { Scan1Service } from './guard/scan1.service';
import { Scan2EmballeurService } from './guard/scan2Emballeur.service';
import { OnlineOrdersGuardService } from './guard/onlineOrdersGuard.service';
import { ReceptionGuardService } from './guard/receptionGuard.service';
import { DefaultService } from './guard/default.service';

import { ReceptionHeaderComponent } from './component/reception/reception-header/reception-header.component';
import { ReceptionDetailComponent } from './component/reception/reception-detail/reception-detail.component';
import { ReceptionTraceComponent } from './component/reception/reception-trace/reception-trace.component';
import { AccueilComponent } from './component/core/accueil/accueil.component';
import { RapportReclamationHome } from './component/reception/rapport-reclamation-home/rapport-reclamation-home';
import { securityManagerService } from './guard/securityManager.service';

import { PackageTypesListComponent } from './component/package-types/package-types-list/package-types-list.component';
import { ScanProblemTypesListComponent } from './component/scanProblemTypes/scanProblemTypes-list/scanProblemTypes-list.component';

import { AuditlogsListComponent } from './component/auth/report/auditlogs-list/auditlogs-list.component';
import { UsersesssionsListComponent } from './component/auth/report/usersesssions-list/usersesssions-list.component';
import { WorkstationSelectionComponent } from './component/auth/workstation-selection/workstation-selection.component';
import { ProductSyncOverviewComponent } from './module/systemManager/reports/ProductSyncOverview/ProductSyncOverview.component';
import { RapportReceptionAcombaComponent } from './component/reception/rapport-reception-acomba/rapport-reception-acomba';
import { OerpAdminPanelComponent } from './component/backendERP/oerp-admin-panel/oerp-admin-panel.component';

import { HhfileGetInfoSommaireH01Component } from './component/home-hardware/hhfile-get-info-sommaire-h01/hhfile-get-info-sommaire-h01.component';
import { hhfileH01GetDetails } from './component/home-hardware/hhfile-h01-get-details/hhfile-h01-get-details.component';
import { hhfileLogExecution } from './component/home-hardware/hhfile-get-log-execution/hhfile-get-log-execution.component';
import { ErrorHandlerService } from './service/core/error-handler.service';
import { RapportExpeditionDvlComponent } from './component/reception/rapport-expedition-dvl/rapport-expedition-dvl.component';
import { TestPrintComponent } from './test-print/test-print.component';
import { hhConsumerNumberMappingListComponent } from './component/hh-consumer-number-mapping/hh-consumer-number-mapping-list/hh-consumer-number-mapping-list.component';
import { RapportFacturationAcombaComponent } from './component/facturationAcomba/rapport-facturation-acomba/rapport-facturation-acomba.component';
import { RapportFacturationSommaireAcombaComponent } from './component/facturationAcomba/rapport-facturation-sommaire-acomba/rapport-facturation-sommaire-acomba.component';
import { OnlineOrderFinancialDetailComponent } from './module/order/order-financial-detail/order-financial-detail.component';

import { UserDefinedRatesListComponent } from './component/userDefinedRates/rates-list/rates-list.component';
import { RatesFormComponent } from './component/userDefinedRates/rates-form/rates-form.component';
import { PickListHeaderListComponent } from './component/pickList/picklist-header-list/picklist-header-list.component';

import { PickListDetailListComponent } from './component/pickList/picklist-detail-list/picklist-detail-list.component';

import { PickListSummarizedListComponent } from './component/pickList/picklist-summarized-list/picklist-summarized-list.component';

import { InvoicesAPListComponent } from './component/supplier/InvoiceAP/invoiceAP-List/invoices-ap-list/invoices-ap-list.component';
import { ProductComboListComponent } from './component/productCombo/product-combo-list/product-combo-list.component';

import { appShippingPurchaseOverviewComponent } from './module/shipping/components/app-shipping-purchase-overview/app-shipping-purchase-overview.component';

import '@progress/kendo-angular-intl/locales/fr-CA/all';
import { CarrierShipmentInvoicesSummaryComponent } from './module/shipping/components/carrier-shipment-invoices-summary.component/carrier-shipment-invoices-summary.component';
import { Scan1Component_OldBefore202405 } from './component/scan1.oldV2Before202405/scan1-oldV2Before202405.component';

// liste des pages de l'application
const routes: Routes = [
  { path: 'accueil', component: AccueilComponent },

  { path: 'logByPin', component: logByPinFormComponent },
  { path: 'regularLogin', component: LoginUserPwdComponent },
  { path: 'chooseWorkstation/:destination', component: WorkstationSelectionComponent },

  { path: 'scan1', component: Scan1Component, canActivate: [DefaultService, Scan1Service] },
  { path: 'scan1-old', component: Scan1Component_OldBefore202405, canActivate: [DefaultService, Scan1Service] },
  { path: 'scan2-emballeur', component: Scan2EmballeurComponent, canActivate: [DefaultService, Scan2EmballeurService] },
  { path: 'expedition-trace', component: RapportExpeditionDvlComponent, canActivate: [DefaultService, OnlineOrdersGuardService] },
  { path: 'commande', component: OnlineOrderListComponent, canActivate: [DefaultService, OnlineOrdersGuardService] },

  { path: 'achat-labels', component: appShippingPurchaseOverviewComponent, canActivate: [DefaultService, OnlineOrdersGuardService] },

  { path: 'reception-header', component: ReceptionHeaderComponent, canActivate: [DefaultService, ReceptionGuardService] },

  //{ path: 'RapportReclamationHome', component: RapportReclamationHome, canActivate: [DefaultService, ReceptionGuardService] },
  { path: 'RapportReclamationHome', component: RapportReclamationHome, canActivate: [DefaultService, ReceptionGuardService] },

  { path: 'reception-detail', component: ReceptionDetailComponent, canActivate: [DefaultService, ReceptionGuardService] },
  { path: 'reception-detail/:id', component: ReceptionDetailComponent, canActivate: [DefaultService, ReceptionGuardService] },
  { path: 'reception-trace', component: ReceptionTraceComponent, canActivate: [DefaultService, ReceptionGuardService] },
  { path: 'reception-trace/:id', component: ReceptionTraceComponent, canActivate: [DefaultService, ReceptionGuardService] },

  { path: 'package-types', component: PackageTypesListComponent, canActivate: [DefaultService] },

  { path: 'scanProblemTypes', component: ScanProblemTypesListComponent, canActivate: [DefaultService] },

  { path: 'manage-workstations', component: WorkstationListComponent, canActivate: [DefaultService, securityManagerService] },

  { path: 'usager', component: UsagerListComponent, canActivate: [DefaultService, securityManagerService] },
  { path: 'usager-form', component: UsagerListComponent, canActivate: [DefaultService, securityManagerService] },
  { path: 'usager-form/:id', component: UsagerListComponent, canActivate: [DefaultService, securityManagerService] },

  { path: 'auditLogList', component: AuditlogsListComponent, canActivate: [DefaultService, securityManagerService] },
  { path: 'userSessionList', component: UsersesssionsListComponent, canActivate: [DefaultService, securityManagerService] },

  {
    path: 'systemManager',
    loadChildren: () => import('src/app/module/systemManager/systemManager.module').then((m) => m.SystemManagerModule),
  },

  {
    path: 'supplier',
    loadChildren: () => import('src/app/module/supplier/supplier.module').then((m) => m.SupplierModule),
  },
  //{ path: 'systemManager-report-BatchExecSummary', component: SystemManagerReportBatchExecSummary, canActivate: [DefaultService, securityManagerService] },
  //{ path: 'systemManager-report-BatchExecDetail', component: SystemManagerReportBatchExecDetail, canActivate: [DefaultService, securityManagerService] },
  //{ path: 'systemManager-report-LogExecSummary', component: SystemManagerReportLogExecSummary, canActivate: [DefaultService, securityManagerService] },
  //{ path: 'systemManager-report-LogExecDetail', component: SystemManagerReportLogExecDetail, canActivate: [DefaultService, securityManagerService] },
  //{ path: 'SystemConfigurationComponent', component: SystemConfigurationComponent, canActivate: [DefaultService, securityManagerService] },

  { path: 'channelAdvisor-ProductSync-Overview', component: ProductSyncOverviewComponent, canActivate: [DefaultService] },

  { path: 'RapportReceptionAcomba', component: RapportReceptionAcombaComponent, canActivate: [DefaultService] },

  { path: 'OerpAdminPanelComponent', component: OerpAdminPanelComponent, canActivate: [DefaultService, securityManagerService] },

  { path: 'HhfileGetInfoSommaireH01Component', component: HhfileGetInfoSommaireH01Component, canActivate: [DefaultService] },
  { path: 'hhfileH01GetDetails', component: hhfileH01GetDetails, canActivate: [DefaultService] },
  { path: 'hhfileLogExecution', component: hhfileLogExecution, canActivate: [DefaultService] },

  { path: 'TestPrintComponent', component: TestPrintComponent, canActivate: [DefaultService] },

  { path: 'hh-consumer-number-mapping', component: hhConsumerNumberMappingListComponent, canActivate: [DefaultService] },

  { path: 'rapport-facturation-acomba', component: RapportFacturationAcombaComponent, canActivate: [DefaultService] },
  { path: 'rapport-facturation-sommaire-acomba', component: RapportFacturationSommaireAcombaComponent, canActivate: [DefaultService] },

  { path: 'rapport-commandes-profit-detail', component: OnlineOrderFinancialDetailComponent, canActivate: [DefaultService] },

  { path: 'user-defined-rates', component: UserDefinedRatesListComponent, canActivate: [DefaultService] },

  { path: 'picklist', component: PickListHeaderListComponent, canActivate: [DefaultService] },
  { path: 'picklist-details', component: PickListDetailListComponent, canActivate: [DefaultService] },
  { path: 'picklist-summarized', component: PickListSummarizedListComponent, canActivate: [DefaultService] },
  { path: 'picklist-summarized/:pickListHeaderId', component: PickListSummarizedListComponent, canActivate: [DefaultService] },
  { path: 'invoiceApList', component: InvoicesAPListComponent, canActivate: [DefaultService] },
  { path: 'product-combo', component: ProductComboListComponent, canActivate: [DefaultService] },
  { path: 'product-combo/:id', component: ProductComboListComponent, canActivate: [DefaultService] },

  { path: 'carrier-invoice-summary', component: CarrierShipmentInvoicesSummaryComponent, canActivate: [DefaultService] },

  { path: '**', component: AccueilComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
